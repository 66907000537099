const ERR_CODE = {
  PROFILE_NOT_FOUND: 'profile_not_found',
  LOGIN: 'login',
  AUTH_VERIFY: 'AuthVerify',
  TERMINATED: 'account_terminator',
  GOOGLE_SYNC: 'GoogleSync',
  FACEBOOK_SYNC: 'FacebookSync',
  RATE_LIMIT: 'ratelimit',
};

export { ERR_CODE };
