import useRequest from './useRequest';

const useGetBanStatus = () => {
  const { originFetch, ...rest } = useRequest();

  const fetch = (accountId) =>
    originFetch(`/api-uni/public/v1/account-ids/uba/ban-status?account_ids=${accountId}`, {
      method: 'GET',
    });

  return { ...rest, fetch };
};

export { useGetBanStatus };
