// @ts-nocheck
import theme from 'clad-ui/theme/chotot';

/**
 * extend and override custom tokens for theme here
 */
const spaceScale = {
  /** 2px; */
  tiny: '2px',
  /** 4px; */
  small: '4px',
  /** 8px; */
  medium: '8px',
  /** 16px; */
  large: '16px',
  /** 32px; */
  extraLarge: '32px',
};

const inputSize = {
  /** 36px */
  regularHeight: '36px',
  /** 300px */
  regularWidth: '300px',
};

const getBorderStyles = ({
  width = theme.borderWidths[1],
  style = theme.borderStyles.solid,
  color = theme.colors.gray2,
}) => `${width} ${style} ${color}`;

const customTheme = {
  ...theme,
  space: {
    ...theme.space,
    spaceScale,
  },
  sizes: {
    ...theme.sizes,
    inputSize,
  },
  shadows: {
    ...theme.shadows,
    baseShadow: theme.shadows.md,
  },
  borders: {
    ...theme.borders,
    /** 1px solid #cacaca */
    regular: getBorderStyles({ color: theme.colors.border }),
    /** 1px solid #9b9b9b */
    focus: getBorderStyles({ color: theme.colors.gray1 }),
    /** 1px solid #d0021b */
    error: getBorderStyles({ color: theme.colors.red2 }),
  },
};

export default customTheme;
